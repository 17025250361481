import { createElement, CSSProperties, PropsWithChildren } from 'react';

import styles from './Flexbox.module.scss';

/**
 * Default component props
 */
type FlexboxProps = PropsWithChildren & {
    align?: CSSProperties['alignItems'];
    as: string;
    classes?: string;
    inline?: boolean;
    justify?: CSSProperties['justifyContent'];
    position: CSSProperties['position'];
};

// type FlexboxProps<T extends keyof JSX.IntrinsicElements> = ComponentProps & Partial<CustomElementProps>;

/**
 * Props prevent both row and column from being passed at the same time
 */
type ColumnProps = FlexboxProps & { column: true; row?: never };
type RowProps = FlexboxProps & { row?: true; column?: never };

type Props = RowProps | ColumnProps;

/**
 * Flexbox component usable as a generic "container"
 * allowing for easy alignment and justification of children,
 * and reduce repeated flexbox styling.
 */

type Flexbox = typeof Flexbox;
export const Flexbox = ({ as = 'div', align, children, inline, justify, position, ...props }: Props) => {
    const directionClass = 'column' in props ? styles['column'] : '';
    const displayClass = inline ? styles['inline-flex'] : '';
    const alignClass = align === 'center' ? styles['center'] : '';
    const fillClass = position === 'absolute' ? styles['fill-inset'] : styles['fill-size'];
    const positionClass = position === 'absolute' ? styles['absolute'] : '';
    const flexClasses = [
        styles.Flexbox,
        directionClass,
        alignClass,
        displayClass,
        fillClass,
        positionClass,
        props?.classes,
    ].join(' ');

    return createElement(as, { ...props, className: flexClasses }, children);
};
